import {
  InternalCreditsPriceID,
  InternalSubscriptionPriceID,
  PagedTaskResults,
  TaskExecutionCardProps,
} from "@/typings";

import {
  LocalSocialManPost,
  PagedLocalSocialManPost,
} from "@/app/SocialManmodeltypings";

import {
  CloudAccessLevels,
  ComfyTokenData,
  LocalModelInputPreset,
  LocalModelInputPresetEntryInput,
  LocalReactionType,
  LocalTaskPrivacyLevels,
  LocalUser,
  MLTaskPropertyEntryData,
  MLTasksData,
  ModelsInfo,
  PagedLocalModelInputPresetResults,
  StripeInvoice,
  StripePayoutHistoryItem,
  TaskExecutionData,
} from "@/modeltypings";

import AWSManager from "@/utils/AWS/AWSManager";
// import AWSManager_SSR from "./AWSManager_SSR";
export interface IBackendManagerInterface {
  clearCache(): void;
  addConfirmSignUpListener(listener: () => void): void;
  addSignInListener(listener: () => void): void;
  addSignOutListener(listener: () => void): void;
  fetchCurrentUserTaskExecutionResults(
    userId?: string,
    nextToken?: string
  ): Promise<PagedTaskResults | undefined>;
  deleteUserTaskResultWithTaskID(taskID: string): Promise<boolean | undefined>;
  deleteUserTaskWorkspaceWithWorkspaceID(
    workspaceID: string
  ): Promise<boolean | undefined>;
  getPublicTasks(
    nextToken?: string,
    authMode?: any,
    limit?: number
  ): Promise<{ results: PagedTaskResults } | undefined>;
  createModel(modelData: ModelsInfo, isPublic: boolean): Promise<ModelsInfo>;
  updateModel(modelData: ModelsInfo): Promise<ModelsInfo>;
  isUserLoggedIn(): Promise<boolean | undefined>;
  updateUserUsername(username: string): Promise<LocalUser | undefined>;
  updateUserRemoveNetworkConnection(network: string): Promise<void>;
  fetchCurrentUserID(): Promise<string | undefined>;
  fetchCurrentUser(): Promise<LocalUser | undefined>;
  fetchUserByID(userID: string, authMode: any): Promise<LocalUser | undefined>;
  batchGetUsersByIDs(ids: string[]): Promise<LocalUser[] | undefined>;
  fetchCurrentUserCredits(): Promise<number | null | undefined>;
  subscribeToTaskExecutionUpdates(
    userId: string | undefined,
    taskId: string | undefined,
    updatesCallback: (updatedTask: TaskExecutionCardProps) => void,
    errorsCallback: (error: any) => void
  ): void;
  unsubscribeToTaskExecutionUpdates(
    userId: string | undefined,
    taskId: string | undefined
  ): void;
  subscribeToUserCreditUpdate(
    userId: string | undefined,
    updatesCallback: (userUpdates: number | null | undefined) => void,
    errorsCallback: (error: any) => void
  ): void;
  subscribeToOnTaskResultCreation(
    userId: string | undefined
  ): Promise<TaskExecutionCardProps>;

  unsubscribeFromSocialManPostUpdates(
    userID: string | undefined,
    postID: string | undefined
  ): void;
  subscribeToSocialManPostUpdates(
    userID: string | undefined,
    postID: string | undefined,
    updatesCallback: (updatedPost: LocalSocialManPost) => void,
    errorsCallback: (error: any) => void
  ): Promise<void>;

  signInWithGoogle(customState?: string): Promise<void>;

  logIn(username: string, password: string): Promise<unknown>;
  signUserOut(): Promise<void>;
  executeTask(
    workspaceId: string | undefined,
    taskExecutionData: TaskExecutionData,
    progressCallback?: (progress: any) => void
  ): Promise<TaskExecutionCardProps>;
  updateTaskExecutionResultsPrivacy(
    taskID: string,
    targetPrivacyLevel: LocalTaskPrivacyLevels
  ): Promise<boolean>;
  addUserReactionToTask(
    userID: string,
    taskID: string,
    reactionType: LocalReactionType
  ): Promise<boolean>;
  //STRIPE///////
  getPaymentHistory(): Promise<StripeInvoice[]>;
  getCustomerPortal(): Promise<any>;
  getStripeConnectAccountPayoutHistory(): Promise<StripePayoutHistoryItem[]>;
  getStripeConnectAccountLoginLink(): Promise<string>;
  getStripeConnectAccountLink(): Promise<string>;
  cashOut(amount: number): Promise<any>;
  createStripeConnectAccount(): Promise<any>;
  deleteStripeConnectAccount(): Promise<any>;
  checkStripeConnectAccountCompletedSetupAndUpdateUser(): Promise<
    boolean | undefined
  >;
  getStripeSessionStatus(session_id: string): Promise<any>;
  stripeCheckout(
    internalPriceID: InternalCreditsPriceID,
    dontRedirect: boolean
  ): Promise<{ clientSecret: string; url: string }>;
  stripeSubscriptionCheckout(
    internalPriceID: InternalSubscriptionPriceID
  ): Promise<{ url: string }>;
  ////TASKS/////
  getTasksByIDGQL(taskID: string): Promise<MLTaskPropertyEntryData | undefined>;
  getTasksGQL(): Promise<MLTasksData | undefined>;
  fetchModelTaskIDFromModelID(modelID: string): Promise<string | undefined>;
  ///PRESETS////////
  createUserPresetForModel(
    modelID: string,
    presetName: string,
    description: string,
    taskName: string,
    modelName: string,
    isPublic: boolean,
    isOpenSource: boolean,
    cost: number,
    entries: LocalModelInputPresetEntryInput[],
    progressCallback: (progress: any) => void
  ): Promise<LocalModelInputPreset>;

  updateUserPresetForModel(
    originalPreset: LocalModelInputPreset,
    presetID: string,
    presetName?: string,
    description?: string,
    isPublic?: boolean,
    isOpenSource?: boolean,
    cost?: number,
    entries?: LocalModelInputPresetEntryInput[]
  ): Promise<LocalModelInputPreset>;

  deleteUserPresetWithPresetID(presetID: string): Promise<string>;
  fetchModelPresetByID(
    presetID: string
  ): Promise<LocalModelInputPreset | undefined>;
  fetchModelPresetByModelID(
    modelID: string
  ): Promise<PagedLocalModelInputPresetResults | undefined>;
  fetchModelPresetByUserID(
    userID: string
  ): Promise<PagedLocalModelInputPresetResults | undefined>;
  fetchCurrentUserModelPresets(): Promise<
    PagedLocalModelInputPresetResults | undefined
  >;
  ///SOCIAL_MAN////////
  fetchCurrentUserSocialManPostByID(
    postID: string,
    authMode: any
  ): Promise<LocalSocialManPost | undefined>;
  fetchCurrentUserSocialManPosts(
    userId?: string,
    nextToken?: string,
    authMode?: any
  ): Promise<PagedLocalSocialManPost | undefined>;
  createSocialManPost(
    title: string,
    description: string,
    userID: string,
    fileData: string,
    progressCallback?: (progress: any) => void
  ): Promise<LocalSocialManPost>;
  updateSocialManPost(localSocialManPost: LocalSocialManPost): Promise<void>;
  deleteSocialManPost(postID: string): Promise<void>;
  postSocialManPostToEverywhere(userID: string, postID: string): Promise<void>;
  getFacebookLoginURL(
    userID: string,
    scopes: string[],
    redirectData?: Record<string, string>
  ): Promise<string>;
  facebookLoginCallback(accessToken: string, userID: string): Promise<void>;
  getLinkedinLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string>;
  getYoutubeLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string>;
  getPinterestLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string>;
  getTwitterLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string>;
  getTiktokLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string>;
  /////USER_CONTENT
  uploadUserContentToS3(
    destination: string,
    content: any,
    level: CloudAccessLevels,
    metadata: {} | null,
    dotExtension: string | null,
    filenameWithoutExtension: string | null,
    progressCompletionPercentCallback?: (progress: any) => void,
    errorCallback?: (error: any) => void
  ): Promise<string>;
  copyUserContentOnS3(
    from: string,
    fromLevel: CloudAccessLevels,
    destination: string,
    toLevel: CloudAccessLevels
  ): Promise<void>;
  getCloudContent(fullKey: string, isContentPublic: boolean): Promise<string>;
  getUserContent(
    customUserID: string | undefined,
    key: string,
    level: CloudAccessLevels
  ): Promise<string>;
  ///CONTACT////
  sendContactEmail(
    email: string,
    subject: string,
    message: string
  ): Promise<string>;
  ///WAITLIST//////
  addToWaitlist(email: string): Promise<void>;
  ///COMFYUI////
  listUserComfyTokens(userID: string): Promise<ComfyTokenData[]>;
  createComfyToken(
    userID: string,
    password: string,
    scopes: string[]
  ): Promise<string>;
  revokeComfyToken(token: string, userID: string): Promise<void>;
}

export enum BACKEND_TYPE {
  AWS = "AWS",
  AWS_SSR = "AWS_SSR",
  MOCK = "MOCK",
}
class BackendManager {
  static currentBackendType: BACKEND_TYPE = BACKEND_TYPE.AWS;
  public static get Instance(): IBackendManagerInterface {
    switch (this.currentBackendType) {
      case BACKEND_TYPE.AWS:
        return AWSManager;
      // case BACKEND_TYPE.AWS_SSR:
      //   return AWSManager_SSR;
    }
    return AWSManager;
  }
}
export default BackendManager.Instance;
