"use client";
import { useState } from "react";
import { useTheme } from "next-themes";
import SunSVG from "../SVGIcons/SunSVG";
import MoonSVG from "../SVGIcons/MoonSVG";

export default function ThemeButton() {
  const { theme, setTheme } = useTheme();
  // const themesArray = ["light", "synthwave", "coffee", "winter", "bumblebee"];
  const [enabled, setEnabled] = useState(theme == "dark");
  const iconSize = 5;
  return (
    <div className="mx-auto my-auto flex">
      <div
        className={`flex rounded-full bg-base-400 bg-secondary text-center text-sm font-medium text-base-400 transition-all duration-200 hover:bg-opacity-40  hover:p-1  hover:text-accent`}
      >
        <label
          htmlFor={`switch to ${enabled ? "light" : "dark"} mode`}
          className={`swap swap-rotate`}
        >
          {/* this hidden checkbox controls the state */}
          <input
            aria-label={`switch to ${enabled ? "light" : "dark"} mode`}
            checked={enabled}
            id={`switch to ${enabled ? "light" : "dark"} mode`}
            type="checkbox"
            onChange={() => {
              setEnabled(!enabled);
              if (enabled) {
                setTheme("light");
              } else {
                setTheme("dark");
              }
            }}
          />

          <SunSVG size={iconSize} className={`swap-on  m-1 fill-current`} />
          <MoonSVG size={iconSize} className={`swap-off  m-1 fill-current`} />
        </label>
      </div>
    </div>
  );
}
