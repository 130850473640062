"use client";
import SimpleLoadingSpinner from "@/components/ui/Loading/SimpleLoadingSpinner";
import ProfileSVG from "@/components/ui/SVGIcons/ProfileSVG";
import BackendManager from "@/utils/BackendManager";
import { getUserPath } from "@/utils/NavigationUtils";
import { Menu, Transition } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import dynamic from "next/dynamic";
import Link from "next/link";
import { Fragment, useState } from "react";
type Props = { usernameOrEmail: string; ppURL: string };
const UserProfileImage = dynamic(
  () => import("@/components/ui/User/UserProfileImage"),
  {
    ssr: false,
  }
);
// import { AWSGlobalHandler } from "@/app/awsGlobal";
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NavigationProfileDropdown({
  usernameOrEmail,
  ppURL,
}: Props) {
  const [loggingOut, setLoggingOut] = useState(false);
  const menuOffset = "mt-4";
  const queryClient = useQueryClient();
  function newMenu() {
    return (
      <Menu as="div" className="relative   text-left">
        <div className="my-auto flex ">
          <Menu.Button className="inline-flex w-full justify-center rounded-full">
            <div
              className={`flex rounded-full bg-base-400 bg-secondary text-center text-sm font-medium text-base-400 transition-all duration-200 hover:bg-opacity-40 hover:p-1 hover:text-accent`}
            >
              {ppURL?.length != 0 && (
                <div className="pointer-events-none mx-auto aspect-square h-7 w-7 overflow-hidden rounded-full bg-secondary">
                  <div className="my-auto flex h-full w-full overflow-hidden rounded-full p-px">
                    <div className="flex h-full w-full overflow-hidden rounded-full">
                      <UserProfileImage
                        isPriority={true}
                        quality={100}
                        alt="Your profile picture"
                        startupLink={ppURL}
                      />
                    </div>
                  </div>
                </div>
                // <Image
                //   className="h-full w-7 rounded-full"
                //   // loader={myLoader}
                //   src={`${ppURL}`}
                //   priority
                //   quality={100}
                //   alt="profile picture"
                //   width="160"
                //   height="160"
                // />
              )}
              {ppURL?.length == 0 && (
                <div>
                  <ProfileSVG size={5} className="m-1 fill-current" />
                </div>
              )}
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {/* <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"></Menu.Items> */}
          <Menu.Items
            className={`absolute right-0 z-10 ${menuOffset} origin-top-right divide-y divide-base-content divide-opacity-20 rounded-md bg-base-200 shadow-lg `}
          >
            <div className="text-s px-2 py-3">
              <div>{usernameOrEmail}</div>
            </div>
            {/* <div className="divider m-0 p-0"></div> */}
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className={classNames(
                      active ? "bg-base-content bg-opacity-10 " : "",
                      "block rounded-md px-4 py-2 text-sm text-base-content"
                    )}
                    href={getUserPath()}
                  >
                    Profile
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              {/* <div className="divider m-0 p-0"></div> */}
              <Menu.Item disabled={loggingOut}>
                {({ active }) => (
                  <Link
                    onClick={() => {
                      setLoggingOut(true);
                      BackendManager.signUserOut().then((x) => {
                        queryClient.removeQueries();
                        // router.push("/clientauthredirect?logout=true");
                      });
                    }}
                    className={classNames(
                      active ? "bg-base-content bg-opacity-10 " : "",
                      "flex w-full flex-row rounded-md px-4 py-2 text-sm text-base-content"
                    )}
                    href="/"
                  >
                    Logout
                    {loggingOut && (
                      <div className="my-auto ml-2">
                        <SimpleLoadingSpinner size={4} />
                      </div>
                    )}
                  </Link>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  return <div>{newMenu()}</div>;
}
